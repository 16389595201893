import React, { useEffect } from "react";
import { LEARNING_RECORDS_ROWS_PER_PAGE } from "../../../constants/generalConstants";
import PaginatedTable from "../../tables/paginatedTable";
import { LXP } from "../../../constants/serviceNames";
import useMyLearningCourses from "../../../services/apiUtils/hooks/useMyLearningCourses";
import LMSCourseRecord from "./lmsCourseRecordRow";
import { Box, Skeleton, Typography } from "@mui/material";

const LoadingSkeleton = () => (
    <>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="100%" />
    </>
);

const LMSCoursesSection = () => {
    const {
        coursesCount,
        coursesList,
        coursesAreLoading,
        loadedPageCount,
        errorMessage,
        setPagesToLoad,
    } = useMyLearningCourses();

    useEffect(() => {
        if (
            loadedPageCount === 1 &&
            coursesCount > LEARNING_RECORDS_ROWS_PER_PAGE
        ) {
            setPagesToLoad(2);
        }
    }, [loadedPageCount, coursesCount, setPagesToLoad]);

    if (coursesAreLoading) {
        return <LoadingSkeleton />;
    }

    if (errorMessage) {
        return (
            <Box
                sx={{
                    margin: "35px 15px",
                    border: "1px solid",
                    paddingBottom: "10px",
                    borderWidth: "0px 0px 3px 0px",
                }}
            >
                <Typography color="black"> {errorMessage}</Typography>
            </Box>
        );
    }

    return (
        <PaginatedTable
            isEditing={false}
            totalItems={coursesCount}
            itemsPerPage={4}
            service={LXP}
            items={coursesList ?? []}
            Row={LMSCourseRecord}
        />
    );
};

LMSCoursesSection.propTypes = {};

export default LMSCoursesSection;
