import {
    alpha,
    Button,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableRow,
    useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { serviceNames } from "../../constants/serviceNames";
import { highContrastBorderSX } from "@sse-digital-services/design-system";
import Link from "@mui/material/Link";
import StyledPagination from "./styledPagination";

const PaginatedTable = ({
    children,
    isEditing,
    items,
    itemsPerPage,
    totalItems,
    service,
    serviceUrl,
    Row,
    onPageChange,
}) => {
    const [page, setPage] = useState(0);
    const theme = useTheme();
    // Ensures that the table doesn't change height on last page
    const tableBodyHeight = `${Math.min(46 * itemsPerPage, 46 * totalItems)}px`;
    const rowsPerPage = Math.min(totalItems > 0 ? totalItems : 0, itemsPerPage);

    const handlePageChange = (event, newPage) => {
        if (onPageChange) {
            onPageChange(newPage);
        }
        setPage(newPage);
    };

    useEffect(() => {
        setPage(0);
    }, [totalItems]);

    const itemsToDisplay = items.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const emptyRowCount = rowsPerPage - itemsToDisplay.length;
    const emptyRows = new Array(emptyRowCount).fill({});

    const rows = itemsToDisplay.map((item) => (
        <Row item={item} key={item.id} isEditing={isEditing} />
    ));

    const serviceName = serviceNames[service] ?? service;

    return (
        <>
            {items?.length > 0 ? (
                <Table
                    aria-label="collapsible table"
                    size="small"
                    sx={{
                        "& .MuiTableCell-sizeSmall": {
                            padding: "2px",
                            overflow: "hidden",
                        },
                    }}
                >
                    {children}
                    <TableBody
                        sx={{ height: tableBodyHeight, verticalAlign: "top" }}
                    >
                        {rows}
                        {emptyRows.map((_, index) => (
                            <TableRow key={`emptyRow-${index}`}>
                                <TableCell sx={{ borderBottom: "none" }} />
                            </TableRow>
                        ))}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <TableCell
                                colSpan={2}
                                sx={{
                                    borderBottom: "none",
                                    overflow: "hidden",
                                    whitespace: "nowrap",
                                }}
                                align="left"
                            >
                                {serviceUrl ? (
                                    <Button
                                        aria-label={`View in ${serviceName}`}
                                        href={serviceUrl}
                                        target="_blank"
                                        disabled={isEditing}
                                        variant="contained"
                                        align={"center"}
                                        sx={{
                                            ...highContrastBorderSX,
                                            backgroundColor:
                                                theme.palette.common.darkTeal,
                                            color: theme.palette.common.white,
                                            minWidth: "130px",
                                            borderRadius: "1px",
                                            maxWidth: "150px",
                                            mx: "0px",
                                            px: "4px",
                                            ":hover": {
                                                backgroundColor: alpha(
                                                    theme.palette.primary.main,
                                                    0.5
                                                ),
                                            },
                                            "&.Mui-disabled": {
                                                backgroundColor:
                                                    "disabled.main",
                                            },
                                            textTransform: "none",
                                        }}
                                    >
                                        View in {serviceName}
                                    </Button>
                                ) : null}
                            </TableCell>
                            <StyledPagination
                                page={page}
                                rowsPerPage={rowsPerPage}
                                handlePageChange={handlePageChange}
                                totalItems={totalItems}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            ) : (
                <Typography
                    m="5px"
                    variant="body3"
                    component="p"
                    color="text.primary"
                >
                    <Link
                        data-track-id={`View in ${serviceName}`}
                        href={isEditing ? undefined : serviceUrl}
                        target="_blank"
                        underline="hover"
                        color="text.primary"
                        variant="body3"
                    >
                        No recent items found, please check {serviceName} for
                        older items
                    </Link>
                </Typography>
            )}
        </>
    );
};

PaginatedTable.propTypes = {
    items: PropTypes.array.isRequired,
    totalItems: PropTypes.number.isRequired,
    isEditing: PropTypes.bool.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    service: PropTypes.string.isRequired,
    serviceUrl: PropTypes.string,
    children: PropTypes.element,
    Row: PropTypes.func.isRequired,
    onPageChange: PropTypes.func,
};

export default PaginatedTable;
