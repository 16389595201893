import { TableCell, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import { GoToServiceButton } from "components/buttons/goToServiceButton";
import { getStatusChip } from "components/chips/statusChips";
import { styledCellSX } from "styled/styledSXProperties/styledPaginatedTable";

const LMSSummaryRow = ({ item, isEditing, service, url }) => {
    const wordWrapStyles = {
        whiteSpace: "normal",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        maxWidth: "120px",
    };

    return (
        <>
            <TableCell
                component="th"
                scope="row"
                sx={{ ...styledCellSX, ...wordWrapStyles }}
            >
                <Typography variant="body1" componet="p">
                    {item.id}
                </Typography>
            </TableCell>
            <TableCell align="left" sx={styledCellSX}>
                {getStatusChip(item.displayState)}
            </TableCell>

            <TableCell align="right" sx={styledCellSX}>
                <GoToServiceButton
                    url={url ?? `https://${item.url}`}
                    colour={"text.primary"}
                    disabled={isEditing}
                    service={service}
                />
            </TableCell>
        </>
    );
};
LMSSummaryRow.propTypes = {
    item: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    service: PropTypes.string.isRequired,
    url: PropTypes.string,
};

export default LMSSummaryRow;
