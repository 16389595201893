import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "@sse-digital-services/design-system";
import BaseRow from "components/tables/baseRow";
import { LXP } from "constants/serviceNames";
import LMSSummaryRow from "./lmsSummaryRow";

const LMSCourseRecord = ({ item }) => {
    let itemDetails = [
        {
            label: "Due Date",
            boldLabel: true,
            value: formatDate(item.dueDate),
        },
        {
            label: "Assigned Date",
            boldLabel: true,
            value: formatDate(item.assignedDate),
        },
        {
            label: "Progress",
            boldLabel: true,
            value: item.progress,
        },
        {
            label: "Type",
            boldLabel: true,
            value: item.type,
        },
        {
            label: "Record Number",
            boldLabel: true,
            value: item.courseId,
        },
    ];

    return (
        <BaseRow itemDetails={itemDetails}>
            <LMSSummaryRow item={item} service={LXP} isEditing={false} />
        </BaseRow>
    );
};
LMSCourseRecord.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string,
        courseTitle: PropTypes.string,
        courseId: PropTypes.string,
        displayState: PropTypes.string,
        status: PropTypes.string,
        dueDate: PropTypes.string,
        deepLink: PropTypes.string,
        assignedDate: PropTypes.string,
        progress: PropTypes.string,
        type: PropTypes.string,
    }),
};

export default LMSCourseRecord;
