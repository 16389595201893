import { Typography, useTheme } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import DashboardTile from "../templates/dashboardTile";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import LMSCoursesSection from "./lmsCourseSection";
import { LXP } from "constants/serviceNames";
import usePreferences from "services/apiUtils/hooks/usePreferences";

const LMSCoursesTile = ({ id, transform, addRectGetter }) => {
    const theme = useTheme();
    const { servicesBasedOnPermissions } = usePreferences();

    return (
        <DashboardTile
            id={id}
            tileColor={theme.palette.background.paper}
            transform={transform}
            addRectGetter={addRectGetter}
        >
            <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{ margin: "0 0 20px 0" }}
            >
                <Typography
                    color="text.primary"
                    variant="h2"
                    component="p"
                    textAlign="left"
                    alignItems="center"
                >
                    My Learning
                </Typography>
            </Box>

            <Divider sx={{ borderBottomWidth: 1, m: "0px" }} variant="middle" />
            <Box
                sx={{
                    overflow: "hidden",
                    maxWidth: "100%",
                }}
            >
                {servicesBasedOnPermissions?.includes(LXP) && (
                    <LMSCoursesSection />
                )}
            </Box>
        </DashboardTile>
    );
};

LMSCoursesTile.propTypes = {
    id: PropTypes.string.isRequired,
    transform: PropTypes.object,
    addRectGetter: PropTypes.func,
};

export default LMSCoursesTile;
