import useErrorHandling, { ErrorTypes } from "../../../errors/useErrorHandling";
import useSWRInfinite from "swr/infinite";
import { useCallback, useMemo } from "react";
import lmsMapper from "../mappers/lmsMapper";
import { setLMSCoursesTaskCount } from "../../../redux/actions/taskCountActions";
import { useDispatch } from "react-redux";
import lmsLearningClient from "../clients/lmsClient";
import useUserProfile from "./useUserProfile";
import usePreferences from "./usePreferences";
import { LXP } from "constants/serviceNames";
import { jwtDecode } from "jwt-decode";

const useMyLearningCourses = () => {
    const dispatch = useDispatch();
    const { userProfileToken, getTokenExpiryTSinMS } = useUserProfile();
    const {
        notificationPreferences,
        servicesInMaintenanceMode,
        preferencesAreLoading,
        servicesBasedOnPermissions,
    } = usePreferences();
    const { throwAsyncError } = useErrorHandling();
    const servicesByPreference =
        notificationPreferences && !preferencesAreLoading
            ? Object.keys(notificationPreferences)
                  .filter((service) => notificationPreferences[service])
                  .sort((a, b) => a.localeCompare(b))
            : null;

    const getCacheKey = (pageIndex) => {
        if (
            !servicesByPreference ||
            !userProfileToken ||
            getTokenExpiryTSinMS(userProfileToken) < Date.now()
        ) {
            return null;
        }
        return ["lxpStatus", pageIndex, ...servicesByPreference];
    };

    const {
        data: coursesData,
        size: loadedPageCount,
        setSize: setPagesToLoad,
        isLoading: coursesAreLoading,
        error: lmsError,
    } = useSWRInfinite(
        getCacheKey,
        async () => {
            try {
                if (
                    servicesBasedOnPermissions.includes(LXP) &&
                    servicesInMaintenanceMode
                ) {
                    if (
                        !servicesInMaintenanceMode.find(
                            (service) => service === LXP
                        )
                    ) {
                        const params = null;
                        const employeeNumber =
                            jwtDecode(userProfileToken)?.EMPLOYEE_NUMBER;

                        const employeeNumberEncoded = employeeNumber
                            ? btoa(employeeNumber)
                            : null;
                        if (
                            employeeNumberEncoded === null ||
                            employeeNumberEncoded === ""
                        ) {
                            throw new Error(
                                "Employee number is not available in the token"
                            );
                        }
                        const courseData = await lmsLearningClient.getLearnings(
                            params,
                            {
                                "user-id": employeeNumberEncoded,
                            }
                        );
                        const coursesMapped =
                            lmsMapper.mapAPICoursesToCourses(courseData);
                        dispatch(setLMSCoursesTaskCount(coursesMapped.count));
                        return coursesMapped;
                    }
                }
            } catch (error) {
                throwAsyncError(
                    ErrorTypes.LMSLearningsAPIError,
                    "Unable to fetch courses from LMS",
                    error
                );
                throw new Error("Unable to fetch courses from LMS");
            }
        },
        { fallbackData: [], parallel: true, revalidateFirstPage: false }
    );

    const coursesList = useMemo(() => {
        return coursesData?.[coursesData.length - 1]?.data || [];
    }, [coursesData]);

    const coursesCount = useMemo(() => {
        return coursesData?.[coursesData.length - 1]?.count || 0;
    }, [coursesData]);

    const courseById = useCallback(
        (id) => {
            if (coursesList) {
                const course = coursesList.find((course) => {
                    return course.id === id;
                });
                return course ?? {};
            }
            return {};
        },
        [coursesList]
    );

    return {
        coursesAreLoading,
        coursesCount,
        coursesList: coursesList,
        loadedPageCount,
        setPagesToLoad,
        courseById,
        errorMessage: lmsError?.message,
    };
};

export default useMyLearningCourses;
